import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Home from './views/home'
import NotFound from './views/not-found'
import ContactUs from './components/ContactUs.vue'; // Adjust the path to your ContactUs component
import TnC from './components/TnC.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import RefundPolicy from './components/RefundPolicy.vue';
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      path: '/contactus',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/tnc',
      name: 'TnC',
      component: TnC
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/refund-policy',
      name: 'RefundPolicy',
      component: RefundPolicy
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
