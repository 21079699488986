<template>
  <div class="contactus-container">
    <app-navigation imageSrc="/"></app-navigation>
    <main class="contactus-main">
      <div class="contactus-content section-container">
        <div class="contactus-max-width max-content-container" style="flex-direction:column">
          <div class="contactus-header">
            <h1 class="contactus-title">Refund Policy</h1>

          </div>


          <div class="shopify-policy__body" style="text-transform:none;">
            <div class="rte">
              <p>&nbsp;</p>
              <p>Last updated: <span style="color:#000000;">11 Mar 2024</span></p>

              <p>We understand that circumstances may change and you may need to reconsider your enrollment in our
                program. To ensure a fair and transparent process, we have established the following refund policy:</p>
              <ol>
                <li>
                  <p><strong>Five-Day Refund Period</strong>:</p>
                  <ul>
                    <li>Students are eligible for a full refund if they choose to opt-out of the program within five
                      days (5 days) of their enrollment date.</li>
                    <li>The refund will be processed within 14 business days from the date of the refund request.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Discontinuation from Internship</strong>:</p>
                  <ul>
                    <li>By opting for a refund within the five-day period, the student agrees to be discontinued from
                      the internship program.</li>
                    <li>This means that the student will no longer have access to any program resources, training
                      materials, or internship opportunities provided by our organization.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Opting Out After Five Days</strong>:</p>
                  <ul>
                    <li>After the five-day refund period has passed, students who wish to discontinue the program will
                      not be eligible for any refund.</li>
                    <li>The decision to discontinue after the refund period will result in the forfeiture of the program
                      fee, and no partial refunds will be issued.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>How to Request a Refund</strong>:</p>
                  <ul>
                    <li>To request a refund, please contact our support team at [<a
                        rel="noreferrer">support@cheeronlife.com</a>] with your enrollment details and reason for the
                      refund.</li>
                    <li>Ensure that your refund request is made within the five-day period to be eligible for a full
                      refund.</li>
                  </ul>
                </li>
                <li>
                  <p><strong>Final Considerations</strong>:</p>
                  <ul>
                    <li>We encourage all students to fully commit to the program and take advantage of the opportunities
                      provided.</li>
                    <li>If you have any questions or concerns about the program or the refund policy, please reach out
                      to us before making your enrollment decision.</li>
                  </ul>
                </li>
              </ol>

            </div>
          </div>



        </div>
      </div>
    </main>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import AppFooter from '../components/footer'

export default {
  name: 'ContactUs',
  components: {
    AppNavigation,
    AppFooter,
  },
}
</script>

<style scoped>
.contactus-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}

.contactus-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.contactus-content {
  position: relative;
  padding-top: 128px;
}

.contactus-max-width {
  z-index: 1;
  align-items: stretch;
}

.contactus-header {
  text-align: center;
  margin-bottom: 2rem;
}

.contactus-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.contactus-description {
  font-size: 1.25rem;
  color: #666;
}

.contactus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactus-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.contactus-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--dl-color-scheme-pink);
}

.contactus-details {
  text-align: left;
}

.contactus-subtitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}
</style>
