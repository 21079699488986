<template>
  <div class="contactus-container">
    <app-navigation imageSrc="/"></app-navigation>
    <main class="contactus-main">
      <div class="contactus-content section-container">
        <div class="contactus-max-width max-content-container">
          <div class="contactus-header">
            <h1 class="contactus-title">Contact Us</h1>
            <p class="contactus-description">
              We'd love to hear from you! Here are our contact details.
            </p>
          </div>
          <div class="contactus-info">
            <div class="contactus-item">
              <i class="fas fa-map-marker-alt contactus-icon"></i>
              <div class="contactus-details">
                <h2 class="contactus-subtitle">Address</h2>
                <p>123 Main Street, Anytown, USA</p>
              </div>
            </div>
            <div class="contactus-item">
              <i class="fas fa-phone contactus-icon"></i>
              <div class="contactus-details">
                <h2 class="contactus-subtitle">Phone</h2>
                <p>+1 234 567 890</p>
              </div>
            </div>
            <div class="contactus-item">
              <i class="fas fa-envelope contactus-icon"></i>
              <div class="contactus-details">
                <h2 class="contactus-subtitle">Email</h2>
                <p>info@company.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <app-footer rootClassName="footer-root-class-name"></app-footer>
  </div>
</template>

<script>
import AppNavigation from '../components/navigation'
import AppFooter from '../components/footer'

export default {
  name: 'ContactUs',
  components: {
    AppNavigation,
    AppFooter,
  },
}
</script>

<style scoped>
.contactus-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
}
.contactus-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contactus-content {
  position: relative;
  padding-top: 128px;
}
.contactus-max-width {
  z-index: 1;
  align-items: stretch;
}
.contactus-header {
  text-align: center;
  margin-bottom: 2rem;
}
.contactus-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.contactus-description {
  font-size: 1.25rem;
  color: #666;
}
.contactus-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactus-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}
.contactus-icon {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--dl-color-scheme-pink);
}
.contactus-details {
  text-align: left;
}
.contactus-subtitle {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}
</style>
