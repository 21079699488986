import { render, staticRenderFns } from "./numbers-card.vue?vue&type=template&id=52b17583&scoped=true"
import script from "./numbers-card.vue?vue&type=script&lang=js"
export * from "./numbers-card.vue?vue&type=script&lang=js"
import style0 from "./numbers-card.vue?vue&type=style&index=0&id=52b17583&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b17583",
  null
  
)

export default component.exports